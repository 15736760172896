import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  ErrorMessages: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor() {}

  /**
   * Shows Error Message
   * @param message 
   */
  showMessage(message: string) {
    if (
      !this.ErrorMessages.getValue() ||
      !this.ErrorMessages.getValue().length
    ) {
      this.ErrorMessages.next([]);
    }

    this.ErrorMessages.getValue().push(message);
    this.ErrorMessages.next(this.ErrorMessages.getValue());
  }

  /**
   * Return unique values from given array
   * @param array 
   * @returns 
   */
  getUnique(array) {
    array.sort();
    for (var i = 1; i < array.length; i++) {
      if (array[i] === array[i - 1]) {
        array.splice(i, 1);
        i--;
      }
    }

    return array;
  }

}
